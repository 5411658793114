import request from '../utils/request'
import baseUrl from './baseUrl'

export const recruitList = (data) => request({
  url: baseUrl + '/recruit/selectForBack',
  method: 'POST',
  data
})
export const recruitAdd = (data) => request({
  url: baseUrl + '/recruit/add',
  method: 'POST',
  data
})

export const recruitDetail = (data) => request({
  url: baseUrl + '/recruit/selectForId',
  method: 'POST',
  data
})
export const recruitDel = (data) => request({
  url: baseUrl + '/recruit/deleteById',
  method: 'POST',
  data
})