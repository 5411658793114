<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>添加招聘</el-breadcrumb-item>
    </el-breadcrumb>
    <hr style="margin-bottom: 20px" />
    <el-form
      ref="formData"
      :model="formData"
      label-width="120px"
      :rules="rules"
    >
      <el-form-item label="岗位名称">
        <el-input v-model="formData.title"></el-input>
      </el-form-item>
      <el-form-item label="工作城市">
        <el-input v-model="formData.city"></el-input>
      </el-form-item>
      <el-form-item label="所属部门">
        <el-input v-model="formData.part"></el-input>
      </el-form-item>
      <el-form-item label="薪资范围">
        <el-input v-model="formData.fee"></el-input>
      </el-form-item>
      <el-form-item label="详情说明">
        <VueUeditorWrap v-model="formData.details" :config="myConfig"></VueUeditorWrap>
      </el-form-item>
      <el-form-item>
        <el-button
          size="mini"
          type="primary"
          style="margin: 20px 20px 0 0"
          @click="sumitForm('formData')"
          >确定</el-button
        >
        <el-button
          size="mini"
          type="primary"
          style="margin: 20px 0"
          @click="$router.go(-1)"
          >返回</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { recruitAdd, recruitDetail } from "../../api/recruit";
import VueUeditorWrap from "vue-ueditor-wrap";
export default {
  components: {
    VueUeditorWrap,
  },
  data() {
    return {
            myConfig: {
        toolbars: [
          [
            'simpleupload', //单图上传
            "fullscreen",
            "source",
            "|",
            "undo",
            "redo",
            "|",
            "bold",
            "italic",
            "underline",
            "fontborder",
            "strikethrough",
            "superscript",
            "subscript",
            "removeformat",
            "formatmatch",
            "autotypeset",
            "blockquote",
            "pasteplain",
            "|",
            "forecolor",
            "backcolor",
            "insertorderedlist",
            "insertunorderedlist",
            "selectall",
            "|",
            "rowspacingtop",
            "rowspacingbottom",
            "lineheight",
            "|",
            "customstyle",
            "paragraph",
            "fontfamily",
            "fontsize",
            "|",
            "directionalityltr",
            "directionalityrtl",
            "indent",
            "|",
            "justifyleft",
            "justifycenter",
            "justifyright",
            "justifyjustify",
            "|",
            "touppercase",
            "tolowercase",
            "|",
            "imagenone",
            "imageleft",
            "imageright",
            "imagecenter",
            "|",
            "emotion",
            "map",
            "insertcode",
            "background",
            "|",
            "horizontal",
            "date",
            "time",
            "spechars",
            "|",
            "inserttable",
            "deletetable",
            "insertparagraphbeforetable",
            "insertrow",
            "deleterow",
            "insertcol",
            "deletecol",
            "mergecells",
            "mergeright",
            "mergedown",
            "|",
            "print",
            "preview",
          ],
        ],
        autoHeightEnabled: false, // 编辑器不自动被内容撑高
        initialFrameHeight: 600, // 初始容器高度
        initialFrameWidth: "50%", // 初始容器宽度
        serverUrl: "/config",
        UEDITOR_HOME_URL: "/static/UEditor/",
        maximumWords: "100000000",
      },
      ueditor: {
        value: "编辑默认文字",
        config: {},
      },
      formData: {
        recruitId: "0",
        title: "",
        city:'',
        part:'',
        fee:'',
        details:''
      },
    };
  },
  created() {
    this.formData.recruitId = this.$route.query.id;
    if (this.formData.recruitId !== "0") {
      this.getFormDetail();
    }
  },

  methods: {
    async getFormDetail() {
      const { data } = await recruitDetail({
        recruitId: this.formData.recruitId,
      });
      if (data.code == 0) {
        this.formData = data.data[0];
      }
    },
    async updatedForm() {
      let that = this;
      const { data } = await recruitAdd(this.formData);
      if (data.code == 0) {
        this.$message({
          type: "success",
          message: data.msg,
        });
        setTimeout(() => {
          that.$router.go(-1);
        }, 500);
      } else {
        this.$message({
          type: "error",
          message: data.msg,
        });
      }
    },
    sumitForm(formData) {
      this.$refs[formData].validate((valid) => {
        if (valid) {
          this.updatedForm();
        } else {
          this.updatedForm();
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
/deep/.el-input__inner {
  width: 300px !important;
}
/deep/.el-textarea__inner {
  width: 300px !important;
}
.title {
  font-weight: bold;
  margin-left: -90px;
  span {
    height: 13px;
    width: 5px;
    background-color: #3082fd;
    display: inline-block;
    margin-right: 5px;
  }
}
</style>